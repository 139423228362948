<template>
  <v-container fluid tag="section">
    <base-material-card icon="mdi-folder-image">
      <slot slot="title">Images</slot>
      <slot slot="rightButtons">
        <toggle-button text="Add a new Image" @toggle="uploadOrEditImage()" :showing="showCreateImageToggle"/>
      </slot>
      <p>This is your image library.  Images can be used and reused for your events.</p>
      <v-row>
        <v-col cols="auto" v-for="(image, i) in images" :key="i">
          <div class="overlay-container">
            <cld-image :publicId="image.id" width="200" crop="scale" />
            <div class="overlay">
              <div>
                <v-btn class="ma-1" small fab dark color="secondary" @click="deleteImage(image)">
                  <v-icon large dark>mdi-delete-circle</v-icon>
                </v-btn>
                <v-btn class="ma-1" small fab dark color="secondary" @click="uploadOrEditImage(image)">
                  <v-icon large dark>mdi-pencil-circle</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';

  export default {

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      }
    },

    data () {
      return {
        currentBoxOfficeId: Store.boxOffice.id,
        boxOffice: {},
        images: [],
        user: {},
        showCreateImageToggle: false,
      }
    },

    created() {
      this.boxOffice.id = this.$route.params.id;
      this.load();
    },
    
    methods: {

      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
        this.readImages();
      },

      async readImages() {
        this.images = await Api.post(this, "Image", "list", this.boxOffice);
      },

      uploadOrEditImage(image) {
        var uploader = window.cloudinary.createUploadWidget({
          cloudName: "happytickets", 
          uploadPreset: "x6cvxr8u",
          folder: this.boxOffice.id,
          cropping: true
          }, (error, result) => { 
            if (error) {
              this.$toast.error(" ", error.message, {position: 'bottomCenter', theme: 'dark', backgroundColor : "red"});
            }
            if (result && result.event === "success") {
              this.refreshImages();
            }
          });
        if (image) {
          uploader.open(null, {files: [image.url]});
        } else {
          uploader.open();
        }
      },

      async refreshImages() {
        await new Promise(r => setTimeout(r, 2000));
        this.readImages();
      },

      async deleteImage(image) {
        await Api.post(this, "Image", "delete", image);
        this.refreshImages();
      },
    }
  }

</script>
<style lang="sass">
  .overlay-container 
    position: relative

  .overlay 
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    opacity: 0
    transition: .5s ease

  .overlay-container:hover .overlay 
    opacity: 1

</style>
